import React from "react";
import { useDataState } from "../../context/data.context";
import styles from "./xml-preview.module.scss";
import { CountryTransfer, GlobalTransfer, useXMLGenerator } from "./use-xml-generator.hook";
import { Button } from "../shared/button/button.component";

const XMLCountryTransfer = () => {
  const state = useDataState();
  const { transferList } = state;

  const countryTransferList = transferList.filter(
    (item) => (item.amount.selected === "PLN" && item.amount.pln) || item.currency === "PLN",
  );

  const transformedData: CountryTransfer[] = countryTransferList.map((item) => ({
    type: "country",
    contractorName: item.contractorName,
    title: `${item.dokument} - ${item.title}`,
    paymentAccount: item.paymentAccount.pln ? item.paymentAccount.pln : item.paymentAccount.default,
    amount: item.amount.pln ? item.amount.pln : item.amount.default,
    currency: "PLN",
    countryCode: "PL",
  }));

  const { xmlData, handleDownload } = useXMLGenerator(transformedData);

  if (transformedData.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>XML dla przelewów PLN ({transformedData.length})</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <td>Tytuł</td>
            <td>Nazwa</td>
            <td>Rachunek</td>
            <td className={styles.textCentered}>Kwota</td>
            <td className={styles.textCentered}>Waluta</td>
            <td className={styles.textCentered}>Kraj</td>
          </tr>
        </thead>
        <tbody>
          {xmlData.PRZELEWY.PRZELEW.map((item) => (
            <tr key={item.NAZWA._text}>
              <td>{item.TRESC._text}</td>
              <td style={{ maxWidth: "200px", height: "50px" }}>{item.NAZWA._text}</td>
              <td>{item.RACHUNEK._text}</td>
              <td className={styles.textCentered}>{item.KWOTA._text}</td>
              <td className={styles.textCentered}>{item.WALUTA._text}</td>
              <td className={styles.textCentered}>{item.KRAJ._text}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button className={styles.btn} onClick={() => handleDownload("przelewy_pln")}>
        Pobierz xml
      </Button>
    </div>
  );
};

const XMLGlobalTransfer = () => {
  const state = useDataState();
  const { transferList } = state;

  const globalTransferList = transferList.filter(
    (item) => item.amount.selected === "default" && item.currency === "EUR",
  );

  const transformedData: GlobalTransfer[] = globalTransferList.map((item) => ({
    type: "global",
    contractorName: item.contractorName,
    title: `${item.dokument} - ${item.title}`,
    paymentAccount: item.paymentAccount.default,
    amount: item.amount.default,
    currency: "EUR",
    countryCode: item.countryCode,
    swift: item.swift,
    bankBranch: item.bankBranch,
    costCover: "SHA",
  }));

  const { xmlData, handleDownload } = useXMLGenerator(transformedData);

  if (transformedData.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>XML dla przelewów EUR ({transformedData.length})</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <td>Tytuł</td>
            <td>Nazwa</td>
            <td>Rachunek</td>
            <td className={styles.textCentered}>Kwota</td>
            <td className={styles.textCentered}>Waluta</td>
            <td className={styles.textCentered}>Kraj</td>
            <td className={styles.textCentered}>Bank oddział</td>
            <td className={styles.textCentered}>Swift</td>
            <td className={styles.textCentered}>Koszty pokrywa</td>
          </tr>
        </thead>
        <tbody>
          {xmlData.PRZELEWY.PRZELEW.map((item) => (
            <tr key={item.NAZWA._text}>
              <td>{item.TRESC._text}</td>
              <td style={{ maxWidth: "200px", height: "50px" }}>{item.NAZWA._text}</td>
              <td>{item.RACHUNEK._text}</td>
              <td className={styles.textCentered}>{item.KWOTA._text}</td>
              <td className={styles.textCentered}>{item.WALUTA._text}</td>
              <td className={styles.textCentered}>{item.KRAJ._text}</td>
              <td className={styles.textCentered}>{item.BANK_ODDZ!._text}</td>
              <td className={styles.textCentered}>{item.BANK_SWIFT!._text}</td>
              <td className={styles.textCentered}>{item.KOSZTY_POKRYWA!._text}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button className={styles.btn} onClick={() => handleDownload("przelewy_eur")}>
        Pobierz xml
      </Button>
    </div>
  );
};

export { XMLCountryTransfer, XMLGlobalTransfer };
